import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import Layout from "../components/layout"
import Seo from "../components/seo"

import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Chip from "@mui/material/Chip"
import MuiLink from "@mui/material/Link"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import NavTabs from "../components/NavTabs"
import { Image } from "@mui/icons-material"
import Jibba from "../images/jibba.jpeg"

const AboutPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout
    location={"About Magesh"}
    title={
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          size="small"
          sx={{
            mr: 1,
            backgroundColor: "action.selected",
            color: "text.primary",
          }}
        >
          <LocalOfferIcon fontSize="small" />
        </IconButton>
        {"About Magesh"}
      </Box>
    }
  >
    <Seo title={"About Magesh"} />
    <Container
      maxWidth="string"
      disableGutters
      sx={{
        maxWidth: "692px",
        "@media (max-width: 600px)": {
          px: "1.5rem",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          "@media (max-width: 600px)": { gap: "1.5rem" },
        }}
      >
        <NavTabs pos={1} />

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          <img src={Jibba} style={{width: '50%'}} />
          <p>
            I'm a Software Consultant from Chennai, India. I've recently moved to New Jersey with my family. I have been building web applications since 2009. I write code for fun. I love
            learning new technologies and teaching people. I work at a company
            called Railsfactory, it's software consulting firm where I help
            people learn technologies and write better code. I run bootcamps,
            workshops and help solve problems.
            <br />
            <br />
            Before that, I was running my own startup for almost 6 years, it was a
            very small software consulting company helping other startups relize
            their dream turning ideas into actual software products. Later, I
            quit to pursue my passion for learning and teaching.
            <br />
            <br />
            Apart from usual work I also love organizing and attending meetups.
            I'm the coordinator at a local developer group called Chennai Geeks.
            I was the lead of Meta Developer Circle Chennai Chapter. We have
            organized a lot of events, meetups, tech talks, hackathon, and much
            more in the last 8 years.
            <br />
            <br />I also love watching tv and reading book. I'm currently
            watching Ted Lasso on apple tv.
          </p>
          <p>
            <b>Random facts:</b>
            <ul>
              <li>I sold credit cards to help my dad after school.</li>
              <li>I bought my first computer in 2007 while i was in college.</li>
              <li>I discovered my passion for coding while i was in 7th grade.</li>
              <li>I have used this thing called Floppy Disk and CD/DVD (not a boomer)</li>
              <li>I was into video games back in 2000s (nostalgic).</li>
              <li>I'm a fan of Jiddu Krishnamurti and his works.</li>
              <li>I love to read philosophy and psychology.</li>
              <li>I can painfully empathize people (wish or curse?)</li>
            </ul>
            <br />
            <b>Favourite quote:</b> <br />
            <br />
            Everyone can have food, clothes and shelter, if we forget our
            nationalities, religions, divisions and become human beings without
            label.
            <br />
            <br />
            <b>Interviews & podcasts:</b>
            <ol>
              <li>
                <Link to="/featured-on-talkoverflow-by-gdg-wtm-chennai/">
                  Google Developers Group Chennai Podcast
                </Link>
              </li>
              <li>
                <Link to="/podcast-mentoring-running-bootcamps/">
                  Stack to Heap Podcast by Manoj Mahalingam
                </Link>
              </li>
            </ol>
            <br />
            <br />
            <b>Articles:</b>
            <ul>
              <li><a href="https://medium.com/railsfactory/the-goat-ruby-on-rails-8-is-here-whats-new-e9c9ab68ffd9" target="_blank">The GOAT Ruby on Rails 8 is here — What’s new?</a></li>
              <li><a href="https://medium.com/railsfactory/ruby-is-not-dying-its-aging-like-fine-wine-952880cecb01" target="_blank">Ruby is not dying — It’s Aging like fine wine</a></li>
              <li><a href="https://medium.com/railsfactory/meta-programming-with-ruby-eval-a-guide-part-1-6c5e54e16811">Meta programming with Ruby Eval: A guide (Part 1)</a></li>
              <li><a href="https://medium.com/railsfactory/meta-programming-with-ruby-eval-a-guide-part-2-5176b7e80e25">Meta programming with Ruby Eval: A guide (Part 2)</a></li>
              <li><a href="https://medium.com/railsfactory/railsfactory-at-rubyconf-india-2023-21501945c13a">RailsFactory at RubyConf India 2023</a></li>
            </ul>
            <br />
            <br />
            <b>Movies & Series:</b>
            <ul>
              <li>Batman Trilogy by Christopher Nolan</li>
              <li>Interstellar</li>
              <li>Spiderman (all except the amazing spiderman)</li>
              <li>Daredevil Series</li>
              <li>Friends</li>
              <li>Big Bang Theory</li>
              <li>Anbe Sivam (Tamil)</li>
            </ul>
            <br />
            <br />
          </p>
        </Box>
      </Box>
    </Container>
  </Layout>
)

AboutPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 200) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
